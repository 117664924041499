import React from "react"
import { Col, Collapse, Row, Typography, Grid, Carousel } from "antd"
import { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allImageSharp {
      nodes {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }
`

require("./ElephantFeaturesSection.less")
const { useBreakpoint } = Grid
const { Title } = Typography
const ElephantFeaturesSection = () => {
  const data = useStaticQuery(query)
  const [hoveredIndex, setHoveredIndex] = useState("0")
  const breakpoints = useBreakpoint()
  const carouselRef = useRef(null)
  const features = [
    {
      title: "Client Management",
      description:
        "Easily manage and collaborate with your clients in one centralized platform.",
      image: 4,
      alt: "accounting client practice management - Elephant CPA",
    },
    {
      title: "Task Management",
      description:
        "Effectively manage your work with organized tasks and activities. ",
      image: 2,
      alt: "accountant task practice management - Elephant CPA",
    },
    {
      title: "Time Tracking",
      description:
        "Intuitively track your time spent on billable and non-billable work.",
      image: 8,
      alt: "accountant time tracking software - Elephant CPA",
    },
    {
      title: "Integrated Billing & Invoicing",
      description: "Seamlessly bill and invoice your clients.",
      image: 0,
      alt: "accounting practice management billing - Elephant CPA",
    },
    {
      title: "Managed Client Requests",
      description:
        "Efficiently request and track information and documents from your clients.",
      image: 5,
      alt: "accounting client request management - Elephant CPA",
    },
    {
      title: "E-Signature",
      description:
        "Securely send and receive electronically signed documents from your clients.",
      image: 3,
      alt: "accounting practice management electronic signature - Elephant CPA",
    },
    {
      title: "In-App Messaging",
      description:
        "Continuously collaborate with your clients and firm through one integrated communication platform.",
      image: 7,
      alt: "accounting practice management messaging - Elephant CPA",
    },
    {
      title: "Electronic Payment Processing",
      description: "Efficiently collect timely payments from your clients.",
      image: 6,
      alt: "accounting practice management online payments - Elephant CPA",
    },
    {
      title: "Secure Document Management",
      description:
        "Maintain and manage all client documents in one central and secure, cloud-based location.",
      image: 1,
      alt: "accounting practice file management - Elephant CPA",
    },
  ]

  useEffect(() => {
    carouselRef.current?.goTo(parseInt(hoveredIndex), false)
  }, [hoveredIndex])

  return (
    <Row justify="center" className="mt-60 mb-30">
      <Col xs={22} xl={21}>
        <Row
          align={
            parseInt(hoveredIndex) < 3
              ? "top"
              : parseInt(hoveredIndex) < 6
              ? "middle"
              : "bottom"
          }
          gutter={30}
          className="mb-60"
        >
          <Col xs={24} className="feature-title-row mt-30">
            <Row justify={breakpoints.sm ? "start" : "center"}>
              <Col>
                <Title level={breakpoints.sm ? 1 : 3}>Elephant Features</Title>
              </Col>
            </Row>
          </Col>
          <Col xl={8} lg={12} xs={24}>
            <Row className="mt-20">
              <Col xs={24} className="feature-description-row">
                <Collapse activeKey={hoveredIndex}>
                  {features.map((feature, index) => {
                    return (
                      <Collapse.Panel
                        showArrow={false}
                        key={`${index}`}
                        header={
                          <Row style={{ width: "100%" }}>
                            <Col
                              id={`${index}`}
                              onMouseEnter={ev => {
                                setHoveredIndex(ev.currentTarget.id)
                              }}
                              xs={22}
                              className={`${
                                breakpoints.sm ? "font-size-20" : "font-size-16"
                              } font-weight-bold default-text-color`}
                            >
                              {feature.title}
                            </Col>
                          </Row>
                        }
                      >
                        <Row>
                          <Col className="feature-description" xs={22}>
                            {feature.description}
                          </Col>
                        </Row>
                        {!breakpoints.lg && (
                          <Row className="mt-20">
                            <Col className="box-shadow-1">
                              <GatsbyImage
                                image={getImage(
                                  data.allImageSharp.nodes[feature.image]
                                )}
                                alt={`${feature.alt}`}
                              />
                            </Col>
                          </Row>
                        )}
                      </Collapse.Panel>
                    )
                  })}
                </Collapse>
              </Col>
            </Row>
          </Col>
          {breakpoints.lg && (
            <Col xl={16} lg={12}>
              <Row justify="end" className="mt-30">
                <Col className="box-shadow-1">
                  <Carousel
                    infinite={false}
                    dots={false}
                    autoplay={false}
                    ref={carouselRef}
                    waitForAnimate={false}
                    fade={true}
                    effect={undefined}
                    swipe={false}
                  >
                    {features.map((feature, index) => {
                      return (
                        <div key={`${index}image`}>
                          <GatsbyImage
                            image={getImage(
                              data.allImageSharp.nodes[feature.image]
                            )}
                            alt={`${feature.alt}`}
                          />
                        </div>
                      )
                    })}
                  </Carousel>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

export default ElephantFeaturesSection
