import React, { useState } from "react"
import { Layout } from "antd"
import LandingPageHeader from "../components/LandingPage/LandingPageHeader"
import LandingPageContent from "../components/LandingPage/LandingPageContent/LandingPageContent"
import LandingPageFooter from "../components/LandingPage/LandingPageFooter"
import SEO from "../shared/Components/Seo"
import SideBar from "../shared/Components/sideBar"

export default function Home() {
  const [clickedFreeTrial, setClickedFreeTrial] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true)
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <SideBar
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
          setClickedFreeTrial={setClickedFreeTrial}
        />

        <Layout>
          <LandingPageHeader
            clickedFreeTrial={clickedFreeTrial}
            setClickedFreeTrial={setClickedFreeTrial}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <LandingPageContent
            clickedFreeTrial={clickedFreeTrial}
            setClickedFreeTrial={setClickedFreeTrial}
          />
          <LandingPageFooter />
        </Layout>
      </Layout>
    </>
  )
}
