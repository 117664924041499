import React, { useState } from "react"
import { Button, Col, Layout, Row } from "antd"
import { CrossIcon, ElephantLogo } from "../../assets/images"
import GetInTouchModal from "./GetInTouchModal"
require("./sideBar.less")
const { Sider } = Layout

const SideBar = ({
  sidebarCollapsed,
  setSidebarCollapsed,
  setClickedFreeTrial,
}) => {
  const [getInTouchModalVisible, setGetInTouchModalVisible] = useState(false)

  const handleShowGetInTouchModalVisible = () => {
    setGetInTouchModalVisible(true)
  }

  const handleHideGetInTouchModalVisible = () => {
    setGetInTouchModalVisible(false)
  }

  return (
    <Sider
      theme={"light"}
      width="100%"
      style={{ height: "100vh", position: "fixed", zIndex: 1000 }}
      collapsedWidth={0}
      trigger={null}
      collapsed={sidebarCollapsed}
    >
      <GetInTouchModal
        getInTouchModalVisible={getInTouchModalVisible}
        handleHideGetInTouchModalVisible={handleHideGetInTouchModalVisible}
      />
      <Row className="sidebar-wrapper-row" style={{}}>
        <Col xs={24}>
          <Row justify="center" className="mt-40">
            <Col xs={19}>
              <Row align="middle">
                <Col flex="auto">
                  <img
                    alt="elephant logo"
                    src={ElephantLogo}
                    width={150}
                    height={50}
                  />
                </Col>
                <Col>
                  <img
                    alt=""
                    onClick={() => {
                      setSidebarCollapsed(true)
                    }}
                    src={CrossIcon}
                    width={25}
                    height={25}
                  />
                </Col>
              </Row>
              <Row className="mt-80 sidebar-action-button" gutter={[0, 10]}>
                <Col xs={24}>
                  <Button
                    href="https://app.elephantcpa.com"
                    type="primary "
                    block
                  >
                    Login
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button
                    onClick={() => {
                      setSidebarCollapsed(true)
                      setClickedFreeTrial(true)
                    }}
                    type="primary"
                    block
                  >
                    Get Free Trial
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button
                    onClick={() => {
                      handleShowGetInTouchModalVisible()
                      setSidebarCollapsed(true)
                    }}
                    type="default"
                    block
                  >
                    Get In Touch
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Sider>
  )
}

export default SideBar
