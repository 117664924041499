import React, { useRef, useState, useEffect } from "react"
import {
  Col,
  Layout,
  Row,
  Typography,
  Button,
  Grid,
  Carousel,
  Form,
  Card,
} from "antd"
import { StaticImage } from "gatsby-plugin-image"
import ElephantFeaturesSection from "./ElephantFeaturesSection"
import Toast from "../../../shared/Components/Toast"
import {
  CoilZinc,
  DottedMaskZinc,
  GreyLeftElephant,
  GreyRightElephant,
} from "../../../assets/images"
import { CheckCircleTwoTone } from "@ant-design/icons"

require("./landingPageContent.less")

const { useBreakpoint } = Grid
const { Content } = Layout
const { Title } = Typography

const LandingPageContent = props => {
  const breakpoints = useBreakpoint()
  const freeTrailRef = useRef(null)
  /*   const [loading, setLoading] = useState(false) */
  const [form] = Form.useForm()

  useEffect(() => {
    if (props.clickedFreeTrial) {
      freeTrailRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
      props.setClickedFreeTrial(false)
    }
  }, [props.clickedFreeTrial])

  return (
    <Content style={{ marginTop: 106 }}>
      <Row className="zinc-background">
        <Col xs={24}>
          {/*         <Row justify="center" className="mt-20">
            <Col className="coming-soon roundBorder">COMING SOON!</Col>
          </Row> */}
          <Row justify="center" className="mt-20" gutter={[0, 6]}>
            <Col xs={20} lg={20} xl={16} xxl={13}>
              <Title
                level={1}
                className={`textCenter ${breakpoints.md ? "font-size-56" : ""}`}
              >
                Streamlining The Relationship Between CPAs And Clients
              </Title>
            </Col>
            <Col xs={24}>
              <Row justify="center">
                <Col
                  className={`${
                    breakpoints.md ? "font-size-24" : ""
                  } textCenter subtitle-text`}
                  xs={20}
                  lg={16}
                  xl={11}
                  xxl={9}
                >
                  Elephant is a secure cloud-based practice management solution
                  for small to midsize accounting firms
                </Col>
              </Row>
            </Col>
            <Col style={{ marginBottom: "20px", marginTop: "20px" }}>
              <iframe
                width={breakpoints.xs ? "360" : "560"}
                height="315"
                src="https://www.youtube.com/embed/LGMLBwhzHtI?rel=0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Col>
            <Col xs={24}>
              <Row justify="center">
                <Col
                  className={`${
                    breakpoints.md ? "font-size-24" : ""
                  } textCenter subtitle-text`}
                  xs={20}
                  lg={16}
                  xl={11}
                  xxl={9}
                >
                  Elephant makes it easy to simplify, streamline and modernize
                  your accounting firm practice management.
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              className="mt-30"
              style={!breakpoints.md ? { marginTop: -20, marginLeft: -40 } : {}}
            >
              <img
                src={DottedMaskZinc}
                alt=""
                width={breakpoints.xl ? 350 : breakpoints.lg ? 250 : 150}
                height={breakpoints.xl ? 350 : breakpoints.lg ? 250 : 130}
              />
            </Col>
            <Col flex="auto">
              <Row justify="end" style={{ marginTop: "-20px" }}>
                <Col xl={{ pull: 3 }} lg={{ pull: 1 }}>
                  <img
                    src={CoilZinc}
                    alt=""
                    width={breakpoints.lg ? 140 : 100}
                    height={breakpoints.lg ? 140 : 100}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <StaticImage
            src="../../../assets/images/ZincBottomBorder.png"
            style={{ width: "100%", height: 50 }}
            alt=""
          />
        </Col>
      </Row>

      <Row justify="center" className="mb-60">
        <Col
          className="dashboard-image carousel-dots box-shadow-1"
          style={{
            width: breakpoints.lg ? "65%" : breakpoints.md ? "75%" : "95%",
          }}
        >
          <div
            style={{
              marginTop: breakpoints.xl ? -410 : breakpoints.lg ? -310 : -150,
            }}
          >
            <Carousel autoplay={true}>
              <div>
                <StaticImage
                  src="../../../assets/images/FirmDashboard.png"
                  alt="accounting firm practice management - Elephant CPA"
                  placeholder="tracedSVG"
                  layout="constrained"
                />
              </div>
              <div>
                <StaticImage
                  src="../../../assets/images/ManageAccountant.png"
                  alt="accountant management system - Elephant CPA"
                  placeholder="tracedSVG"
                  layout="constrained"
                />
              </div>
              <div>
                <StaticImage
                  src="../../../assets/images/AccountantDashboard.png"
                  alt="accountant workflow practice management - Elephant CPA"
                  placeholder="tracedSVG"
                  layout="constrained"
                />
              </div>
            </Carousel>
          </div>
        </Col>
      </Row>
      <Row ref={freeTrailRef} justify="center" className="mb-30">
        <Col xs={22}>
          <Row className="free-trial-row">
            <Col {...(breakpoints.lg ? {} : { xs: 24 })}>
              <img
                src={GreyLeftElephant}
                alt="grey elephant"
                height={200}
                width={200}
              />
            </Col>
            <Col flex="1 0 50%">
              <Row
                justify="center"
                align="bottom"
                style={breakpoints.lg ? {} : { marginTop: -200 }}
              >
                <Col flex="1 0 50%">
                  <Row
                    className="mt-60"
                    justify="center"
                    style={
                      breakpoints.lg ? {} : { position: "absolute", zIndex: 1 }
                    }
                  >
                    <Col xxl={13} xl={19} lg={24} xs={22}>
                      <Title
                        className={`textCenter ${
                          breakpoints.md ? "font-size-54" : ""
                        }`}
                        level={breakpoints.sm ? 2 : 3}
                      >
                        Take advantage of our free 30-day trial and experience
                        the benefits of Elephant practice management!
                      </Title>
                    </Col>
                    <Col xs={24}>
                      <Row justify="center">
                        <Col
                          justify="center"
                          xs={22}
                          lg={24}
                          xl={16}
                          xxl={11}
                          className={`${
                            breakpoints.sm
                              ? "get-free-trial-input-sm"
                              : "get-free-trial-input-xs"
                          } mt-30 mb-60`}
                        >
                          <Button
                            type="primary"
                            href="https://app.elephantcpa.com/signup"
                          >
                            Get Free Trial
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col {...(breakpoints.lg ? {} : { xs: 24 })}>
                  <Row
                    justify="end"
                    align="bottom"
                    style={
                      breakpoints.lg
                        ? {}
                        : breakpoints.md
                        ? { marginTop: 170 }
                        : breakpoints.sm
                        ? { marginTop: 210 }
                        : { marginTop: 210 }
                    }
                  >
                    <Col>
                      <img
                        src={GreyRightElephant}
                        alt=""
                        height={200}
                        width={200}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="zinc-background wrapper-row-elephant-features">
        <Col xs={24}>
          <ElephantFeaturesSection />
        </Col>
      </Row>
      <Row justify="center" className="practice-management-section-row">
        <Col xs={22} lg={21} xl={22} xxl={20}>
          <Row justify="center">
            <Col className="mt-60" flex="1 0 20%">
              <Title
                className={breakpoints.md ? "" : "textCenter"}
                level={
                  breakpoints.xl ? 1 : breakpoints.md && !breakpoints.lg ? 3 : 2
                }
              >
                <Row ref={props.test}>
                  <Col xs={24}>Total Practice Management,</Col>
                  <Col xs={24}>One Price Per User</Col>
                </Row>
              </Title>
            </Col>
            <Col>
              <StaticImage
                src="../../../assets/images/PeopleWheel.png"
                width={700}
                className="peopleWheel"
                placeholder="tracedSVG"
                alt="Accounting practice management software solution - Elephant CPA"
                style={
                  {
                    /*                   marginTop: breakpoints.xl
                    ? "-80px"
                    : breakpoints.md
                    ? -10
                    : 30, */
                  }
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="site-card-wrapper">
        <Row gutter={24} justify="center">
          <Col span={breakpoints.md ? 8 : 24} className="card">
            <Card hoverable title="Free Trial" className="card-title">
              <Col>
                <p className="card-price">30-Day Trial / No Cost</p>
                <div className="card__details">
                  <ul>
                    <li>
                      <CheckCircleTwoTone /> Full Feature Availability*
                    </li>
                    <li>
                      <CheckCircleTwoTone /> No User Limit
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Quick Start Guide
                    </li>
                    <li>
                      <CheckCircleTwoTone /> No Credit Card Required
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Upgrade Anytime
                    </li>
                  </ul>
                </div>
              </Col>
              <div className="card__footer-free">
                <Button
                  href="https://app.elephantcpa.com/signup"
                  className="card__footer-cta"
                  type="primary"
                >
                  Sign Up Now
                </Button>
              </div>
            </Card>
          </Col>
          <Col span={breakpoints.md ? 8 : 24} className="card">
            <Card
              hoverable
              title="Monthly Plan"
              className="card-title"
              bordered={false}
            >
              <Col>
                <p className="card-price">
                  <span className="card-price-amount">$49.95 / </span>
                  <span className="card-price-user">User/Month</span>{" "}
                </p>
                <div className="card__details">
                  <ul>
                    <li>
                      <CheckCircleTwoTone /> Full Feature Availability*
                    </li>
                    <li>
                      <CheckCircleTwoTone /> No User Limit
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Free Client Accounts
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Quick Start Guide
                    </li>
                    <li>
                      <CheckCircleTwoTone /> User Support
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Pay Month-to-Month
                    </li>
                    <li>
                      <CheckCircleTwoTone /> No Contract, Cancel Anytime
                    </li>
                  </ul>
                </div>
              </Col>
              <div className="card__footer">
                <Button
                  href="https://app.elephantcpa.com/signup"
                  className="card__footer-cta"
                  type="primary"
                >
                  Sign Up Now
                </Button>
              </div>
            </Card>
          </Col>
          <Col span={breakpoints.md ? 8 : 24} className="card">
            <Card
              hoverable
              title="Annual Plan"
              className="card-title"
              bordered={false}
            >
              <Col>
                <p className="card-price">
                  <span className="card-price-amount">$45.00 / </span>
                  <span className="card-price-user">User/Month</span>{" "}
                </p>
                <p className="card-price-badge">Save 10%!</p>
                <div className="card__details">
                  <ul>
                    <li>
                      <CheckCircleTwoTone /> Pay Annually -{" "}
                      <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                        Save 10%!
                      </span>
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Full Feature Availability*
                    </li>
                    <li>
                      <CheckCircleTwoTone /> No User Limit
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Free Client Accounts
                    </li>
                    <li>
                      <CheckCircleTwoTone /> Quick Start Guide
                    </li>
                    <li>
                      <CheckCircleTwoTone /> User Support
                    </li>
                    <li>
                      <CheckCircleTwoTone /> No Contract, Cancel Anytime
                    </li>
                  </ul>
                </div>
              </Col>
              <div className="card__footer">
                <Button
                  href="https://app.elephantcpa.com/signup"
                  className="card__footer-cta"
                  type="primary"
                >
                  Sign Up Now
                </Button>
              </div>
            </Card>
          </Col>
          <Col className="feature-box">
            <p>
              *Full Feature Availability includes: Task Management, Time
              Tracking, Client Management, Billing & Invoicing, Electronic
              Payment Processing, Secure Document Storage, In-App Messaging,
              E-Signature for Documents, Managed Client Requests
            </p>
          </Col>
        </Row>
      </div>
    </Content>
  )
}

export default LandingPageContent
